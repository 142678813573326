.event-item {
  padding: 0.5rem 1rem;
  border-left: 4px solid $blue;
  box-shadow: $littleShadow;
  border-radius: 3px;
  margin-bottom: 0.5rem;
}
.event-short-date {
  font-size: 1.75rem;
  color: $DarkGray;
}
.event-name {
  font-size: $fontSizeLarge;
  font-weight: bold;
  @extend .darkgray;
}
.event-type {
  font-size: $fontSizeLarge;
  font-weight: bold;
}
.event-item-details {
  margin-top: 1rem;
}
.event-place {
  // font-size: $fontSizeLarge;
}
.event-description {
  color: $DarkGray;
  white-space: pre-line;
}
.event-modal {
  .event-date .calendar-icon {
    font-size: $fontSizeLarge;
  }
}
