.modal-header {
  background: $blueGradient;
  color: white;
  font-weight: bold;
  border: 0;
  align-items: center;
  min-height: 57px;
  padding: 0 1rem;
}

.modal-backdrop {
  background-color: #182142;
}

.modal-dialog {
  // max-width: 550px;
}

.modal-content {
  border: 0;
}

.close-btn {
  cursor: pointer;
}

.toast {
  z-index: 99999999999;
  left: 0;
  bottom: 0;
  margin: 0.5rem;
  border: 0;
  position: fixed;
  cursor: pointer;
  box-shadow: $niceShadow;
  .toast-header {
    background-color: white;
    padding: 0.5rem 1rem;
    font-size: $fontSizeLarge;
    border: none;
    @extend .darkgray;
  }
  .toast-body {
    padding: 1rem;
  }
}

.push-notif {
  animation: pushnotif 400ms ease forwards;
  position: fixed;
  // width: 350px;
  background: white;
  box-shadow: $niceShadow;
  border-radius: $radius;
  left: 50%;
  z-index: 1000;
  top: 1rem;
  .push-notif-header {
    @extend .row-between;
    padding: 0 0.5rem;
    border-radius: $radius $radius 0 0;
    background-color: $LightGray;
    .close-btn {
      color: $Gray;
    }
  }
  .push-notif-title {
    // font-weight: 600;
    @extend .darkgray;
  }
  .push-notif-body {
    padding: 0.5rem;
  }
}

.connected-notif, .alert-notif {
  @extend .row-flex;
  padding: 1rem;
}

.alert-dialog {
  padding: 1.5rem;
  .alert-dialog-header {
    @extend .row-center;
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  .alert-dialog-body {
    @extend .row-center;
    text-align: center;
    margin-bottom: 1.5rem;
    white-space: pre-wrap;

  }
  .alert-dialog-footer {
    @extend .row-center;
  }
}