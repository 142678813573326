// .fc-h-event {
//   background-color: $blue !important;
//   box-shadow: $littleShadow !important;
//   border: 0 !important;
// }
.fc-toolbar-chunk {
  div:first-child {
    @extend .row-flex;
  }
  .fc-toolbar-title {
    text-transform: capitalize;
    @extend .darkgray;
    font-weight: bold;
    margin-left: 1rem;
  }
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1rem;
}

.fc-toolbar-chunk {
  @extend .row-flex;
}


.fc-button-group {
  @extend .button-gp;
  height: 45px;
}

.fc-button {
  margin: 0.25rem !important;
  border-radius: 10px !important;
  color: $LightDark !important;
  border: none !important;
  background-color: transparent !important;
  &:focus, &:hover {
    box-shadow: $littleShadow !important;
  }
  &.fc-button-active {
    background-color: white !important;
    box-shadow: $littleShadow !important;
  }
  &.fc-invitations-button {
    color: $blue !important;
  }
  &.fc-prev-button, &.fc-next-button {
    padding: 0.25rem !important;
    @extend .darkgray;
  }
}

.no-invitations .fc-button.fc-invitations-button {
  color: $MediumGray !important;
  cursor: auto;
  &:hover, &:focus {
    box-shadow: none !important;
  }
}

.fc-today-button {
  color: $blue !important;
  &:disabled {
    box-shadow: none !important;
  }
}

@include media-breakpoint-down(lg) {
  .event-page {
    .fc .fc-toolbar {
      display: block;
      text-align: center;
      .fc-toolbar-chunk {
        margin-bottom: 1rem;
      }
    }
    .fc-header-toolbar {
      margin: 0 !important;
    }
    .page {
      margin: 0 !important;
      padding: 1rem 0;
    }
  }
}

//GRID
.fc-theme-standard .fc-scrollgrid {
  transition: 200ms;
  // border: 1px solid $MidGray !important;
  border-radius: $radius;
  td {
    // border: none !important;
    &.fc-timegrid-slot {
      border: 1px solid $LighterGray !important;
    }
    &.fc-timegrid-slot-minor {
      border: 1px solid $MidGray !important;
    }
    &.fc-scrollgrid-shrink {
      border: 1px solid $MidGray !important;
    }
  }
  th {
    border: none !important;
    border-bottom: 1px solid $MidGray !important;
  }
  .fc-day:hover {
    background-color: $LightGray;
  }
  .fc-scrollgrid-sync-table {
    tr {
      border-top: 1px solid $MidGray !important;
    }
  }
}
.fc-daygrid-day-top {
  justify-content: center !important;
}

.fc-daygrid-day {
  border: none !important;
}

.fc-event-main-frame {
  padding-left: 0.25rem;
  padding-top: 0.25rem;
}

.urioz-event {
  border: none;
  border-left: 2px solid;
  border-radius: 0px;
  font-weight: 600;
}