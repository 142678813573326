.folder-dragging {
  background-color: rgba($green, 0.1);
  border: 1px solid $green;
}
.file-dragging {
  opacity: 0.3;
}

.isDragContainer {
  background-color: rgba($green, 0.1);
}

.drive-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@include media-breakpoint-down(md) {
  .drive-page {
    .drive-topbar {
      display: block;
      text-align: center;
      padding: 0 1rem;
      .drive-search {
        margin-bottom: 1rem;
      }
    }
    .page {
      margin: 0 !important;
      padding: 1rem 0;
    }
  }
}

.drive-secondary-bar {
  @extend .row-between;
  background-color: $LighterGray;
  padding: 0 1rem;
  min-height: 50px;
  .breadcrumb {
    background-color: transparent !important;
    margin: 0;
  }

}

.drive-tooltip {
  background-color: white !important;
  box-shadow: $niceShadow;
  min-width: 300px;
}

.drive-table {
}

.t-header {
  font-weight: 600;
  position: sticky;
  top: $layoutHeader;
  z-index: 1;
  padding: 0 1rem;
  @extend .back-primary
}
.t-border {
  height: 1px;
  margin: 0 0.5rem;
}
.t-body {
  min-height: 100px;
  outline: none;
  .drive-row {
    transition: 200ms;
    padding: 0 1rem;
    position: relative;
    cursor: pointer;
    @extend .row-flex;
    &:hover {
      background-color: $LighterGray;
    }
    &:focus {
      outline: none !important;
    }
  }
}
.c-1 {
  flex: 0 0 64px;
}
.c-body-1 {
  padding: 0.75rem;
}
.c-2 {
  flex: 3 3 auto;
  padding: 0.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.c-3 {
  flex: 0 0 215px;
  padding: 0.75rem;
}
.c-4 {
  flex: 0 0 215px;
  padding: 0.75rem;
}
.c-5 {
  flex: 0 0 150px;
  padding: 0.75rem;
}
.c-6 {
  flex: 0 0 45px;
}
@include media-breakpoint-down(lg) {

  .c-3 {
    flex: 0 0 150px;
  }
}

.move-frame {
  position: absolute;
  right: 0;
  z-index: 100;
  border-top: 4px solid $blue;
  border-radius: 3px;
  top: 100%;
  width: 350px;
  background: white;
  box-shadow: $niceShadow;
}

.drive-light {
  .drive-light-header {
    @extend .row-flex;
    padding: 0.5rem;
  }
  .drive-light-table {
    overflow: auto;
    height: 250px;
    border-top: 1px solid $MidGray;
    border-bottom: 1px solid $MidGray;
  }
  .file-light-row {
    padding: 0.5rem;
    &:hover {
      background-color: $LighterGray;
    }
  }
}

.breadcrumb-item {
  &.active {
    cursor: pointer;
  }
  &:hover {
    color: $blue;
  }
}

.upload-frame {
  box-shadow: $littleShadow;
  border-radius: $radius;
  width: 400px;
  @extend .back-primary;
  .uploaded-el {
    padding: 0 1rem;
  }
}

.file-card-label {
  padding: 0.5rem 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: $fontSizeSmall;
  .file-card-name {
    font-weight: 600;
  }
}
.file-card-dropdown {
  position: absolute;
  top: 0;
  right: 0;
}

// .canFolder {
//   background-color: rgba($green, 0.1);
// }
.folderBase {
  border: 1px solid transparent;
}
.overFolder {
  background-color: rgba($green, 0.1);
  border: 1px solid rgba($green, 0.5);
}