// Fonts

@font-face {
  font-family: "Custom";
  font-weight: 400;
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Custom";
  font-weight: 600;
  src: url('../fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: "Custom";
  font-weight: 700;
  src: url('../fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: "Custom";
  font-weight: 900;
  src: url('../fonts/Poppins-Bold.ttf') format('truetype');
}

// @font-face {
//   font-family: "Custom";
//   font-weight: 900;
//   src: url('../fonts/Poppins-Black.ttf') format('truetype');
// }


// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


.row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.col-between-center {
  @extend .col-between;
  align-items: center;
}

.col-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fw-bold {
  font-weight: bold;
}

.fw-600 {
  font-weight: 600;
}

.flex-1 {
  flex: 1;
}

.row-flex {
  display: flex;
  align-items: center;
}

.row-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

body {
  color: $LightDark
}

// @import 'custom/builder/application.scss';
@import 'custom/animation.scss';
@import 'custom/common.scss';
@import 'custom/layout.scss';
@import 'custom/button.scss';
@import 'custom/auth.scss';
@import 'custom/message.scss';
@import 'custom/input.scss';
@import 'custom/orgchart.scss';
@import 'custom/tabs.scss';
@import 'custom/rooms.scss';
@import 'custom/card.scss';
@import 'custom/event.scss';
@import 'custom/comment.scss';
@import 'custom/calendar.scss';
@import 'custom/home.scss';
@import 'custom/projects.scss';
@import 'custom/darkmode.scss';
@import 'custom/modal.scss';
@import 'custom/fullScreenLoading.scss';
@import 'custom/text.scss';
@import 'custom/group.scss';
@import 'custom/form.scss';
@import 'custom/dropdown.scss';
@import 'custom/member.scss';
@import 'custom/drive.scss';
