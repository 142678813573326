
.created-at,
.event-item,
.form-title,
.minimize-btn,
.room-item,
.t-body .drive-row,
label,
.group-title,
.toast-body,
a
{
  color: $DarkGray;
}


.drive-secondary-bar,
.css-1rhbuit-multiValue,
.member-item:hover,
.replied-message,

.sidebar {
  background: white;
  color: $PrimaryDark;
  .sidebar-item:hover {
    background-color: $LightGray;
  }
}
.t-border {
  background-color: $MidGray;
}
.divider {
  background-color: $MidGray;
}


.darkmode {

  .navbar {
    box-shadow: none
  }
  .sidebar {
    .sidebar-item:hover {
      background-color: $LightDark;
    }
  }
  .room-container .room-header {
    border-top: 1px solid $DarkGray;
  }
  .page {
    background: $PrimaryDark;
    box-shadow: none;
  }

  .form-control, .form-control:focus {
    color: $LightGray;
  }

  .drive-secondary-bar,
  .css-1rhbuit-multiValue,
  .member-item:hover,
  .replied-message

  .mobile-menu-panel {
    background-color: $SecondaryDark;
  }
  .t-border {
    background-color: $DarkGray;
  }
  .divider {
    background-color: $DarkGray;
  }
  .darkgray {
    color: $MediumGray;
  }
  .other-message .message {
    background-color: $SecondaryDark;
    color: $LightGray;
  }
  .card {
    background-color: $SecondaryDark;
    color: $MediumGray;
  }
  .dateline {
    color: $LightGray;
  }

  .t-header,
  .notif-frame,
  .modal-content,
  .upload-frame,
  .room-bottom-bar input,
  .new-message-content textarea,
  .new-message-content textarea:focus {
    background: $PrimaryDark;
    color: $LightGray;
  }

  .css-yk16xz-control, .css-1pahdxg-control {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: $PrimaryDark;
  }

  .notif:hover {
    background-color: $LightDark;
  }
  .profile-actions {
    background-color: $LightDark;
  }

  .created-at,
  .event-item,
  .form-title,
  .minimize-btn,
  .room-item,
  .t-body .drive-row,
  label,
  .section-title,
  .group-title,
  .toast-body,
  a
  {
    color: $MediumGray;
  }

  .toast .toast-body {
    background-color: $SecondaryDark;
  }

  .light-hover {
    &:hover {
      background-color: $LightDark;
    }
  }

  .room-item:hover {
    background-color: $LightDark !important;
  }

  .t-body .drive-row:hover {
    background-color: $LightDark;
  }

  .editable-text:hover {
    background-color: $LightDark;
  }
  .custom-tooltip {
    background-color: $LightDark;
    border: 1px solid $DarkGray;
  }
  .fc-theme-standard .fc-scrollgrid {
    border-color: $DarkGray !important;
    th, tr {
      border-color: $DarkGray !important;
    }
    td {
      border-color: $DarkGray !important;
      &.fc-timegrid-slot {
        border: 1px solid $DarkGray !important;
      }
      &.fc-timegrid-slot-minor {
        border: 1px solid $LightDark !important;
      }
    }
    .fc-day:hover {
      background-color: $LightDark;
    }
  }
  .fc-button-group {
    background-color: $SecondaryDark !important;
  }
  .fc-button {
    color: $LightGray !important;
    &.fc-button-active {
      background-color: $LightDark !important;
    }
  }
}

