.dropdown-frame {
  animation: dropdown 400ms;
  background-color: white;
  white-space: nowrap;
  border-radius: 3px;
  z-index: 100;
  box-shadow: $niceShadow;
  color: $DarkGray;
}

.drop-btn {
  cursor: pointer;
  // border-radius: 50%;
  // width: 40px;
  // height: 40px;
  // &:hover {
  //   box-shadow: $niceShadow;
  // }
  // &:focus {
  //   box-shadow: $niceShadow;
  // }
}

.dropdown-item {
  padding: 1rem;
  transition: 200ms;
  color: $DarkGray;
  cursor: pointer;
  &:hover {
    background-color: $LightGray;
  }
}
