.project-view {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 140px);
}

.ticket-col {
  @extend .back-primary;
  // @extend .border-gray;
  border-radius: $radius;
  min-height: 100px;
  transition: 200ms;
  height: 100%;
  .ticket-col-header {
    position: sticky;
    top: 0;
    @extend .back-primary;
    @extend .px-3, .py-2;
    &:before {
      transition: 200ms;
      opacity: 0;
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      height: 7px;
      box-shadow: 0px 3px 3px 0px #00000012;
      pointer-events: none;
      background-color: #ffffff;
    }
  }
}

.is-scrolled .ticket-col-header:before {
  opacity: 1;
}


.overDrop {
  background-color: rgba($blue, 0.2);
}
// .canDrop {
//   background-color: rgba($green, 0.1)
// }

.ticket-title {
  font-weight: 600;
  padding-right: 0.5rem;
}

.add-column-btn {
  @extend .ticket-col;
  @extend .row-center;
  cursor: pointer;
  min-width: 56px;
  min-height: 56px;
  height: 56px;
  position: sticky;
  top: 0;
  &:hover {
    color: $blue;
  }
}

.ticket-column-container {
  width: 100%;
  max-width: calc(33.3333% - 1rem);
  margin: 0 0.5rem;
  flex: 1;
  .column-menu-btn {
    padding: 0.5rem;
    justify-content: center;
    // display: none;
    cursor: pointer;
    svg {
      transition: 200ms;
      font-size: 2rem;
      color: $MediumGray;
    }
    &:hover {
      svg {
        color: $Gray;
      }
    }
  }
  &:hover {
    .column-menu-btn {
      // display: flex;
    }
  }
}

.ticket-container {
  padding: 0.5rem;
  .ticket {
    padding: 0.5rem;
    border-left: 5px solid;
    box-shadow: 0 0 5px 0 #3b40532b;
    border-radius: $radius;
    @extend .back-primary;
    cursor: grab;
    transition: 200ms;
    &:hover {
      @extend .back-light;
    }
    &.late-ticket {
      background-color: rgba($red, 0.1);
    }
  }
  .ticket-image {
    margin: -0.5rem;
    margin-bottom: 1rem;
    @extend .border-gray-bottom;
    border-radius: $radius;
    display: flex;
    img {
      max-width: 100% !important;
      margin: auto;
      max-height: 250px;
    }
  }
  .ticket-body {
    font-size: $fontSizeSmall;
    color: $Gray;
    margin-bottom: 0.5rem;
  }
  .ticket-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.deadline-ticket {
  padding: 1rem;
  margin: -1rem -1rem 0 -1rem;
  background-color: rgba($blue, 0.1);
  &.late {
    background-color: rgba($red, 0.1);
  }
}

.new-ticket-btn {
  margin: auto;
  @extend .back-primary;
  @extend .border-gray;
  height: 50px;
  width: 100%;
  svg {
    font-size: 1.5rem;
    color: $blue;
  }
}
.new-column-input {
  padding: 1rem;
  height: 100px;
  @extend .back-primary;
  @extend .border-gray;
  display: flex;
  justify-content: space-between;
  input {
    width: 100%;
  }
}


.project-header {
  padding: 1rem 0;
}
.check-item {
  @extend .row-between;
  // padding: 0.25rem;
  // margin: 0 -0.25rem;
}
.is-done {
  .check-item-content {
    color: $MediumGray;
    text-decoration: line-through;
    text-decoration-color: $green;
  }
}

.avatar-list {
  @extend .row-flex;
  .avatar-item {
    margin-left: -6px;
  }
}

// @keyframes openColumnForm {
//   from { height: 0px;}
//   to   { height: 100px;}
// }

// .column-form {
//   animation: openColumnForm 400ms forwards;
// }