// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Custom', sans-serif;
$fontSizeSmall: 0.75rem;
$fontSizeBase: 0.9rem;
$fontSizeLarge: 1.1rem;
$line-height-base: 1.6;

$littleShadow: 0 0 4px 0 #7b89943b;
$niceShadow: 0 0 20px 0 #7b89943b;

// Colors
$blue: #4a66cd;
// $blue: #5a79e8;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #cb4c65;
$red: #cc4842;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #41d0a6;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$PrimaryDark: #292c35;
$SecondaryDark: #343844;
$StrongDark : #1a171c;
$MediumDark : #252028;
$LightDark  : #2f323c;
$DarkGray   : #535767;
$Gray       : #737990;
$MediumGray : #babfd1;
$MidGray    : #e2e5ec;
$LightGray  : #f8faff;
$LighterGray: #f8f8fb;

// ROOM VARIABLE
$roomHeaderH: 70px;
$roomBottomBarH: 55px;
$addHeight: 0px;
$navbarHeight: 60px;
$roomBodyH: calc(100vh - #{$navbarHeight});
$roomsSidebarWidth: 250px;
$navbarShadow:0 3px 3px 1px #0000001c;

$radius: 3px;

$lightBorder: 1px solid $LightGray;
$lighterBorder: 1px solid $LighterGray;

$blueGradient: linear-gradient(180deg, #6a83e0, $blue);
