.message-row {
  padding: 0.5rem;
  @extend .border-gray-bottom;
  cursor: pointer;
  &:hover {
    box-shadow: 0 2px 2px 0 rgba($DarkGray, 0.2);
  }
}

.messages-list {
  @extend .border-gray-top;
  height: calc(100vh - #{$navbarHeight} - 140px );
  overflow: auto;
}

.new-message-content {
  .form-control {
    border: 0;
  }
  textarea {
    border-bottom: 1px solid $MediumGray !important;
    &:active {
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.css-yk16xz-control, .css-1pahdxg-control {
  border-radius: 0 !important;
  min-height: 50px !important;
  box-shadow: none !important;
  .css-1rhbuit-multiValue {
    color: $DarkGray;
    border-radius: 3px;
    .css-12jo7m5 {
      color: $DarkGray;
      padding: 0.25rem 0.5rem;
    }
  }
}

.paperclip-btn {
  cursor: pointer;
  color: $DarkGray;
  padding: 0.5rem;
  transition: 200ms;
  &:hover {
    background-color: $LightGray;
  }
  svg {
    color: $DarkGray;
    font-size: 1.5rem;
  }
}

.new-message-to {

  .form-control {
    border: 0;
    border-bottom: 1px solid $Gray;
  }
}

.replied-message {
  padding: 1rem;
  color: $DarkGray;
}

.message-show-content {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  @extend .darkgray;
  @extend .back-light;
}

.message-head {
  flex: 2;
}
.message-from {
  flex: 8;
}
.message-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 18;
}
.message-date {
  font-size: $fontSizeSmall;
  flex: 4;
}

.attachment-row {
  font-size: $fontSizeSmall;
  color: $DarkGray;
  background-color: $LightGray;
  padding: 0.5rem;
  border-radius: 3px;
  margin-bottom: 0.25rem;
  svg {
    cursor: pointer;
  }
}

.attachment {
  width: 200px;
  height: 250px;
  font-size: 0.75rem;
  padding: 1rem;
  color: $DarkGray;
  background-color: $LighterGray;
}
