.icon-btn {
  display: flex;
  color: $blue;
  transition: 200ms;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  @extend .border-gray;
  @extend .back-light;
  cursor: pointer;
  svg {
    font-size: $fontSizeLarge;
    margin: auto;
  }
  &:hover {
    box-shadow: 0 0 5px 0 #7b89943b;
  }
  &.active {
    background-color: $blue;
    color: white;
  }
}
.icon-btn-light {
  @extend .icon-btn;
  border: none !important;
  background: transparent;
  &:hover {
    // background-color: $LightGray !important;
    @extend .back-secondary;
    box-shadow: none !important;
  }
}

.btn.disabled {
  opacity: 1;
  color: $Gray;
}

.gray-btn {
  color: $MediumGray;
  cursor: pointer;
  width: 35px;
  height: 35px;
  @extend .row-center;
  &:hover {
    color: $Gray;
  }
}

.media-file {
  position: absolute;
  opacity: 0;
  width: 0;
}

.link {
  cursor: pointer;
}

.menu-responsive {
  font-size: 25px;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  svg {
    margin: auto;
    margin-left: 0;
  }
}

.btn-link, .btn-link:focus {
  box-shadow: none !important;
}

.btn-primary {
  background: $blueGradient;
  border: 0;
  box-shadow: 0 3px 5px 0 rgba($blue, 0.6);
  &:focus {
    box-shadow: 0 3px 5px 0 rgba($blue, 0.6) !important;
  }
  &:hover {
    box-shadow: 0 4px 6px 0 rgba($blue, 0.6) !important;
  }
  &:active {
    box-shadow: 0 3px 5px -4px rgba($blue, 0.6) !important;
    border: 0;
  }
}

.btn-success {
  background: linear-gradient(180deg, #65d6ad, $green);
  border: 0;
  color: white;
  box-shadow: 0 3px 5px 0 rgba($green, 0.4);
  &:focus {
    box-shadow: 0 3px 5px 0 rgba($green, 0.4) !important;
  }
  &:hover {
    box-shadow: 0 4px 6px 0 rgba($green, 0.4) !important;
  }
  &:active {
    box-shadow: 0 3px 5px -4px rgba($green, 0.4) !important;
    border: 0;
  }
}

.btn-danger {
  background: linear-gradient(180deg, #dc5049, $red);
  border: 0;
  box-shadow: 0 3px 5px 0 rgba($red, 0.4);
  &:focus {
    box-shadow: 0 3px 5px 0 rgba($red, 0.4) !important;
  }
  &:hover {
    box-shadow: 0 4px 6px 0 rgba($red, 0.4) !important;
  }
  &:active {
    box-shadow: 0 3px 5px -4px rgba($red, 0.4) !important;
    border: 0;
  }
}

.btn-pink {
  background: linear-gradient(180deg, #de7086, $pink);
  border: 0;
  box-shadow: 0 3px 5px 0 rgba($pink, 0.4);
  &:focus {
    box-shadow: 0 3px 5px 0 rgba($pink, 0.4) !important;
  }
  &:hover {
    box-shadow: 0 4px 6px 0 rgba($pink, 0.4) !important;
  }
  &:active {
    box-shadow: 0 3px 5px -4px rgba($pink, 0.4) !important;
    border: 0;
  }
}

.btn-light {
  @extend .back-secondary;
  border: 0;
}

.floating-mobile-btn {
  position: fixed;
  bottom: 2.0rem;
  right: 1.25rem;
  border-radius: 50%;
  z-index: 100000;
  // background: linear-gradient(20deg, $Pink, $Yellow);
  background: $blueGradient;
  width: 60px;
  height: 60px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  svg {
    margin: auto;
    color: white !important;
    font-size: 22px;
    color: white;
  }
}

.positionner {
  margin: 0 0.25rem;
  .up {
    width: 2rem;
    display: flex;
    height: 2rem;
    justify-content: center;
    align-items: center;
    background-color: $LightGray;
    &:hover {
      background-color: $MediumGray;
    }
    cursor: pointer;
  }
  .down {
    width: 2rem;
    display: flex;
    height: 2rem;
    border-top: 1px solid $MidGray;
    justify-content: center;
    align-items: center;
    background-color: $LightGray;
    &:hover {
      background-color: $MediumGray;
    }
    cursor: pointer;
  }
}

.button-gp {
  background-color: $LighterGray;
  border-radius: 10px;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  a {
    margin: 0.25rem !important;
    border-radius: 10px !important;
    color: $LightDark !important;
    border: none !important;
    background-color: transparent !important;
    padding: 0.4em 0.65em;
    font-size: 1em;
    line-height: 1.5;
    &:focus, &:hover {
      box-shadow: $littleShadow !important;
    }
    &.active {
      background-color: white !important;
      box-shadow: $littleShadow !important;
    }
  }
}