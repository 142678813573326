.auth-page {
  background-image: url("https://www.10wallpaper.com/wallpaper/1366x768/1602/River_pink_morning_sunrise-Scenery_HD_Desktop_Wallpaper_1366x768.jpg");
  background-size: cover;
  height: 100vh;
  background-position: center;
  display: flex;
}

.auth-card-container {
  margin: auto;
}
.auth-card {
  max-width: 700px;
  width: calc(100vw - 1rem);
  // margin: 1rem;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 30px -5px $MediumDark;
  padding: 1.5rem;
  .auth-card-header {
    // padding: 1rem;
  }
  .auth-card-body {
    .form-group {
      position: relative;
      svg {
        position: absolute;
        left: 1.5rem;
        transform: translate(-50%, -50%);
        top: 50%;
        font-size: 1.4rem;
        color: $MediumGray;
      }
    }
    .input-icon {
      padding-left: 3rem;
    }
    input {
      height: 60px;
      box-shadow: inset 0 2px 8px #ce98a326;
      &::placeholder {
        color: $MediumGray;
      }
      &:focus {
        svg {
          color: $pink;
        }
      }
    }
    // padding: 1rem;
  }
  .subtitle {
    text-align: center;
    color: $DarkGray;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: bold;
  }
}
