.member-item {
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 0 -1rem;
  // background-color: $LightGray;
  @extend .light-hover;
}

.relation-info {
  font-size: $fontSizeSmall;
}
.activity-circle {
  position: absolute;
  bottom: 0px;
  display: flex;
  right: -5px;
}
