a {
  text-decoration: none !important;
  &:hover {
    color: $blue;
  }
  &.active {
    color: $blue !important;
  }
}

.capitalize {
  text-transform: capitalize;
}

.break-word {
  word-break: break-word;
}

.pointer {
  cursor: pointer;
}

.close-pop {
  cursor: pointer;
  padding: 1rem;
  margin: -1rem;
}

.settings-body {
  padding: 1.5rem;
}

.settings-tabs {
  @extend .row-center;
  a {
    padding: 1rem 1.5rem 0.5rem 1rem;
    &.active {
      border-bottom: 2px solid $blue;
    }
  }
}

.avatar-letter {
  margin: auto;
  font-weight: 800;
  text-transform: uppercase;
}

.form-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: $DarkGray;
}

.search-overlay {
  background-color: #3c51a438;
  position: fixed;
  width: 100%;
  z-index: 1000;
  height: 100%;
  top: 0;
}

.search-item-base {
  padding: 1rem;
  margin: 0 -1rem;
  @extend .light-hover;
}

.editable-text {
  // transition: 200ms;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  margin: 0 -0.5rem;
  &.empty {
    color: $Gray;
    font-weight: 200;
  }
  &:hover {
    background-color: $LightGray;
    border-radius: $radius;
  }
  &.disabled {
    cursor: auto;;
    &:hover {
      background-color: transparent;
    }
  }
}

.light-hover {
  &:hover {
    background-color: $LightGray;
  }
}

.search-title {
  font-size: $fontSizeLarge;
  font-weight: bold;
  padding: 1rem;
  margin: 0 -1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  @extend .darkgray;
  @extend .row-between;
  @extend .back-light;
  .search-count {
    font-size: 1rem;
    font-weight: 600;
  }
  svg {
    font-size: 2rem;
  }
}

.bg-primary {
  background-color: $blueGradient;
}

.created-at {
  font-size: $fontSizeSmall;
}

.dateline {
  padding: 1rem 0;
  font-weight: 600;
}

.announce {
  color: white;
  font-size: $fontSizeLarge;
  font-weight: 600;
  white-space: pre-line;
  text-align: center;
}

.bg-text {
  background-color: rgba($blue, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 3px;
}

.light-bg-success {
  background-color: rgba($green, 0.1);
}
.light-bg-danger {
  background-color: rgba($red, 0.1);
}
.light-bg-warning {
  background-color: rgba($yellow, 0.1);
}

.circle {
  position: absolute;
  bottom: -5px;
  right: -5px;
  background-color: $red;
  width: 18px;
  height: 18px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.75rem;
}

.assignable-select-title {
  padding: 0.75rem 1rem;
  background-color: #f8fafc;
}

.visiocall-frame {
  position: fixed;
  padding: 1rem;
  cursor: grab;
  border-radius: 6px;
  left: 50%;
  top: 20%;
  transform: translate(-50%, 0);
  background-color: white;
  z-index: 1000;
  box-shadow: $niceShadow;
}

.visiocalling-frame {
  position: fixed;
  padding: 1rem;
  border-radius: 6px;
  cursor: grab;
  left: 50%;
  top: 20%;
  transform: translate(-50%, 0);
  background-color: white;
  z-index: 1000;
  box-shadow: $niceShadow;
}

.realtime-frame {
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translate(-50%, 0);
  background-color: white;
  z-index: 1000;
  border-radius: 6px;
  box-shadow: $niceShadow;
  .active-users-panel {
    @extend .row-between;
    padding: 0.5rem;
    cursor: grab;
  }
  .actions-video {
    display: flex;
    width: 100%;
    justify-content: center;
    .buttons-video {
      border-radius: 30px;
      display: flex;
    }
    .video-action-btn {
      cursor: pointer;
      width: 40px;
      padding: 0.5rem;
      display: flex;
      svg {
        margin: auto;
      }
    }
  }
  .video-container {
    position: relative;
    display: flex;
    width: 600px;
    // height: 400px;
  }

  .remote-videos {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }
  .remote-video {
    width: 100%;
    background-color: $LightDark;
    border-radius: 0 0 6px 6px;
  }
  .local-video {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30%;
    border-radius: 0 0 6px 0;
  }
}

.visio-mini {
  margin: 0 0.5rem;
  padding: 0 0.5rem 0 1rem;
  @extend .border-gray;
  width: 100%;
  border-radius: 30px;
  background-color: $LightGray;
  @extend .row-between;
}

.profile-name {
  font-size: 1.5rem;
  font-weight: bold;
  @extend .darkgray;
}

.profile-info {
  width: fit-content;
  margin: auto;
  color: $DarkGray;
  font-size: $fontSizeLarge;
  & > * {
    margin-bottom: 1.5rem;
  }
  svg {
    font-size: 1.5rem;
  }
  &.profile-info-small {
    font-size: 1rem;
    svg {
      font-size: 1.3rem;
    }
  }
}

.profile-actions {
  @extend .row-center;
  padding: 1rem;
  margin: 0 -1rem;
  background-color: $LightGray;
}

.post-it {
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
  background: linear-gradient(178deg, #f5f2c4, #efed7f);
  box-shadow: 2px 2px 8px -2px #25396869, inset -12px 11px 11px -15px #0000001f;
}
.post-it-delete {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 0.5rem;
  color: #a4a7b38f;
  &:hover {
    color: #a4a7b3;
  }
}

.post-it-content {
  width: 90%;
}

.custom-tooltip {
  animation: opacity 400ms ease forwards;
  opacity: 0;
  position: absolute;
  z-index: 10;
  background: white;
  border-radius: 6px;
  box-shadow: 0 0 20px 0 #2f344736;
  min-width: 250px;
}

.notif-frame {
  animation: growup 400ms forwards;
  position: absolute;
  right: 1rem;
  border-top: 4px solid $blue;
  border-radius: 3px;
  top: 100%;
  width: 350px;
  padding: 1rem 0.5rem;
  min-height: 100px;
  overflow: hidden;
  @extend .back-primary;
  box-shadow: $niceShadow;
}

.custom-frame {
  position: absolute;
  border-top: 4px solid $pink;
  z-index: 1;
  border-radius: 3px;
  top: 100%;
  width: 350px;
  padding: 1rem 0.5rem;
  margin-top: 0.5rem;
  min-height: 100px;
  overflow: hidden;
  @extend .back-primary;
  box-shadow: $niceShadow;
}

.selectable-row {
  padding: 0.5rem;
  border-radius: 12px;
  transition: 200ms;
  cursor: pointer;
  &:hover {
    background-color: $LightGray;
  }
  &.active {
    background-color: rgba($blue, 0.1);
  }
}

.notif {
  @extend .selectable-row;
  font-size: $fontSizeSmall;
  .notif-text {

  }
  .notif-date {
    @extend .darkgray;
  }
  &.read-notif {
    filter: opacity(0.5);
  }
}

.dropzone {
  padding: 2rem;
  outline: none;
  border: 2px dashed rgba($blue, 0.1);
  border-radius: 12px;
  @extend .darkgray;
  text-align: center;
  font-size: $fontSizeSmall;
  &:hover {
    background-color: $LightGray;
  }
  &.drag-active {
    background-color: rgba($blue, 0.1);
  }
}


.messaging-tabs {
  white-space: nowrap;
  padding-right: 0.5rem;
}

.project-tab {
  white-space: nowrap;
  padding-right: 0.5rem;
}

.admin-tabs {
  white-space: nowrap;
  padding-right: 0.5rem;
}
.admin-tab {
  border-radius: 12px;
  padding: 0.5rem 1rem;
  transition: 200ms;
  margin-right: 1rem;
  cursor: pointer;
  &:hover {
    @extend .back-light;
  }
}

.active .admin-tab {
  background-color: rgba($blue, 0.1);
}

.active .admin-tab.admin-tab-white, .admin-tab.admin-tab-white:hover {
  @extend .back-primary;
}

.drag-preview {
  background-color: pink;
}
.item-list__item {
  background-color: black;
}

.running-visio {
  width: 400px;
  margin: 1rem;
  background-color: white;
  right: 0;
  position: absolute;
  padding: 1rem;
  border-radius: $radius;
  box-shadow: $niceShadow;
  .visio-icon {
    @extend .darkgray;
    width: 40px;
    animation: breathin ease-in 1600ms infinite;
  }
}

.page-link, .page-item.disabled .page-link {
  @extend .back-primary;
  @extend .border-gray;
}