// .drive-search {
//   display: flex;
//   position: relative;
//   input {
//     font-size: 1.1rem;
//     height: 55px;
//     padding-left: 60px;
//     border-radius: 30px;
//     background-color: $LightGray;
//     border: 0;
//     &:focus {
//       box-shadow: inset 0 0 4px 0.2rem $LightGray
//     }
//   }
// }

.form-control {
  border-radius: 3px;
  &::placeholder {
    color: $Gray;
  }
  &:focus {
    box-shadow: 0 0 2px 0 $blue
  }
}

.form-control, .form-control:focus {
  color: $DarkGray;
  @extend .border-gray;
  @extend .back-primary;
}

.input-row {
  margin-bottom: 1rem;
  label {
    font-weight: 600;
  }
}

.icon-input {
  position: relative;
  .icon {
    transition: 300ms;
    position: absolute;
    right: 0.5rem;
    font-size: 18px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.universal-search {
  svg {
    @extend .darkgray;
  }
}

.color-input {
  .smart-input {
    opacity: 0;
    width: 0px;
    height: 0px;
  }
  .color-circle {
    border-radius: 50%;
    height: 1.75rem;
    width: 1.75rem;
    border: 1px solid #9d9d9d63;
    cursor: pointer;
  }
}


.select-design {
  position: relative;
  .select-button {
    width: 100%;
    min-width: 150px;
    height: 40px;
    transition: 200ms;
    @extend .back-primary;
    cursor: pointer;
    display: flex;
    // @extend .border-gray;
    border-radius: $radius;
    // &:hover {
    //   background-color: $LighterGray;
    // }
    .caret {
      position: absolute;
      top: 50%;
      color: $DarkGray;
      right: 1rem;
      transform: translateY(-50%);
      font-size: $fontSizeLarge;
    }
    .select-icon {
      width: 2rem;
      height: 2rem;
      background-color: #7856e6;
      display: flex;
      border-radius: $radius;
      color: white;
      margin: 0 0.5rem;
      font-size: $fontSizeLarge;
      &-blue {
        @extend .select-icon;
        background-color: $blue;
      }
      svg {
        margin: auto;
      }
    }
    .select-title {
      font-weight: 600;
    }
    .selected-value {
      color: $Gray;
      padding-right: 3rem;
    }
  }
  .select-options {
    animation: zoomIn 200ms ;
    box-shadow: $niceShadow;
    border-radius: $radius;
    padding: 0.5rem 0;
    position: absolute;
    z-index: 10;
    @extend .back-primary;
    width: 100%;
    .option {
      cursor: pointer;
      padding: 0.5rem 1rem;
      white-space: nowrap;
      color: $DarkGray;
      font-weight: 600;
      &.selected {
        color: $blue;
      }
      &:hover {
        background-color: $LighterGray;
      }
    }
  }
}

@keyframes floating {
  from {top: 1rem; font-size: 1rem;}
  to {top: 0; font-size: 0.85rem;}
}


.floating-input {
  position: relative;
  .floating-label, input {
    transition: all 0.2s;
  }
  .form-control {
    // background-color: white;
    padding-top: 0.5rem;
    border-width: 1px;
    color: $PrimaryDark;
    min-height: calc(2.5rem + 0.75rem + 2px);
    padding: 0.5rem 1rem 0.25rem 1rem;
    &:focus {
      box-shadow: none;
      border-color: rgba($blue, 0.75);
    }
    &::placeholder {
      color: #b1b4c1;
    }
  }

  .form-control:not(:placeholder-shown) + label, .form-control:focus + .floating-label {
    animation: floating 200ms forwards;
    padding: 1.5rem;
    display: block;
    top: 0;
    @extend .back-primary;
    padding: 0 0.5rem;
    color: #b1b4c1;
  }
  .form-control:focus + .floating-label {
    color: $blue;
  }
  .form-control:focus::placeholder {
    color: white;
  }

}

.smart-datepicker {
  display: flex;
  border-radius: 3px;
  @extend .border-gray;
  @extend .back-primary;
  .form-control, .form-control:focus {
    border: none;
    padding: 0.5rem 0rem 0.25rem 0rem !important;
  }
}
.floating-label {
  position: absolute;
  border-radius: 30px;
  left: 0.6rem;
  background-color: transparent;
  color: $MediumGray;
  padding: 0 0rem;
  transform: translate(0, -50%);
  display: none;
  cursor: text;
}

.react-date-picker__calenda {
  z-index: 10;
}

.react-date-picker__wrapper {
  border: none !important;
  @extend .mt-2;
}
.with-time .react-date-picker__wrapper {
  @extend .mt-1;
}

.react-date-picker__inputGroup {
  padding-left: 10px;

}

.react-date-picker__inputGroup input, .react-date-picker__clear-button .react-date-picker__button svg, .react-date-picker__button {
  color: inherit !important;
  outline: none !important;
}

.react-calendar {
  @extend .border-gray;
  min-width: 440px;
  line-height: 1.4em;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border-radius: 10px;
  abbr {
    text-decoration: none;
  }
  .react-calendar__tile {
    border-radius: 50%;
    max-width: 60px;
    height: 60px;
    &:hover {
      background-color: $LightGray;
    }
  }
  .react-calendar__tile--now {
    background-color: $blue;
    &:hover {
      background-color: $blue;
    }
  }
  .react-calendar__month-view__days__day--weekend {
    color: $red;
    &.react-calendar__tile--now {
      color: white;
    }
  }
  .react-calendar__month-view__weekdays__weekday {
    @extend .darkgray;
  }
  .react-calendar__navigation__label {
    font-weight: bold;
    color: $LightDark;
    border-radius: $radius;
    &:enabled:hover {
      background-color: $LightGray;
    }
  }
  .react-calendar__navigation__arrow:enabled:hover {
    border-radius: $radius;
    background-color: $LightGray;
  }
  .react-calendar__month-view__weekdays {
    .react-calendar__month-view__weekdays__weekday {
      padding: 0;
    }
  }
}


$transition: all .4s ease;

/* Hide the input */
input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.check-trail {
  display: flex;
  align-items: center;
  width: 2.55rem;
  height: 1.55rem;
  margin-bottom: 0;
  @extend .back-primary;
  // box-shadow: 0 0 1px 0 #0000002e;
  @extend .border-gray;
  border-radius: 2.5em;
  transition: $transition;
  cursor: pointer;
}
.check-handler {
  display: flex;
  margin-left: 0.125rem;
  justify-content: center;
  align-items: center;
  width: 1.35rem;
  height: 1.35rem;
  border-radius: 50%;
  transition: $transition;
  @extend .back-primary;
  // box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.2);
  @extend .border-gray;
  // &:before {
  //   content: "\2714";
  //   color: transparent;
  //   font-size: 1rem;
  //   font-weight: bold;
  // }
}
input[type="checkbox"]:checked + .check-trail {
  border: 1px solid transparent;
  background: rgba($green, 1);
  .check-handler {
    border: 1px solid transparent;
    margin-left: calc(50% - 0.2rem);
    // &:before {
    //   content: "\2714";
    //   color: rgba($green, 1);
    // }
  }
}

.smart-switch {
  @extend .row-flex;
  .switch-label {
    margin-left: 0.75rem;
    @extend .darkgray;
  }
  &.smart-switch-primary {
    input[type="checkbox"]:checked + .check-trail {
      border: 1px solid transparent;
      background: rgba($blue, 1);
    }
  }
}

.styled-input-single {
  position: relative;
  padding: 0.25rem 0 0.25rem 2.5rem;
  text-align: left;
  label {
    margin-bottom: 0;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      border-radius: 50%;
    }
    &:before {
      left: 0;
      width: 30px;
      height: 30px;
      margin: -15px 0 0;
      // background: #f7f7f7;
      @extend .border-gray;
    }
    &:after {
      left: 5px;
      width: 20px;
      height: 20px;
      margin: -10px 0 0;
      opacity: 0;
      background: $green;
      // transform: translate3d(-40px, 0, 0) scale(0.5);
      transition: 200ms;
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: -9999px;
    visibility: hidden;
    &:checked + label {
      &:before {
      }
      &:after {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
}

.fake-input {
  cursor: text;
  padding: 0.5rem 0;
  @extend .border-gray-bottom;
  color: $MediumGray;
  width: 100%;
}

.list-inputs-item {
  .grip-vertical {
    // opacity: 0;
    color: $Gray;
  }
  &:hover .grip-vertical {
    // opacity: 1;
  }
}

.light-input {
  border: 0;
  border-bottom: 1px solid transparent;
  outline: none;
  padding: 0.5rem 0;
  width: 100%;
  &:hover {
    @extend .border-gray-bottom;
  }
  &:focus, &:focus:hover {
    transition: 200ms;
    border-bottom: 1px solid $blue;
  }
}
