$transition-duration: 2s;
$path-length: 157px; // Retrieved using SVG's getTotalLength()

.fullscreenloading {
  height: 100vh;
  display: flex;
  width: 100vw;
  // background: linear-gradient(20deg, #3f2d30, #49111c);
  svg {
    overflow: initial !important;
    width: 100px;
    height: 150px;
    
    g {
      animation: slide $transition-duration linear infinite;
      
      &:nth-child(2) {
        animation-delay: $transition-duration / 4;
        
        path {
          animation-delay: $transition-duration / 4;
          stroke-dasharray: 0px $path-length + 1;
          stroke-dashoffset: 1px;
        }
      }
    }
    
    path{
      stroke: url(#gradient);
      stroke-width: 20px;
      stroke-linecap: round;
      fill: none;
      stroke-dasharray: 0 $path-length;
      stroke-dashoffset: 0;
      animation: escalade $transition-duration cubic-bezier(0.8, 0, 0.2, 1) infinite;
    }
  }
}
.loading-text {
  text-align: center;
  color: $DarkGray;
  font-size: 2rem;
}


@keyframes slide {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(50px);
  }
}

@keyframes escalade {
  0% {
    stroke-dasharray: 0 $path-length;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: $path-length - 1 $path-length;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: $path-length - 1 $path-length;
    stroke-dashoffset: -($path-length - 1);
  }
}