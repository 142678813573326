

.flux-rss {
  height: 70%;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  padding: 1rem;
  // background: linear-gradient(0deg, #292c35, #292c359c);
  // box-shadow: 0 -30px 24px 0 #292c35a6;
  @extend .row-center;
  position: absolute;
}

.flux-text {
  @extend .back-primary;
  border-radius: 40px;
  padding: 1rem 2rem;
  box-shadow: $niceShadow;
  white-space: pre;
  @extend .darkgray;
}

.home-page .page {
  margin-top: -4rem;
}

// .letter {
//   -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
//   clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
//   transform: translateY(-50px);
//   opacity: 0;
//   animation-name: titleAnimation;
//   animation-timing-function: ease;
//   white-space: pre;
//   // color: white;
//   @extend .darkgray;
//   font-size: 1.3rem;
//   animation-duration: 5.5s;
// }
// h1 span{
//   // animation-delay: 0.6s;
//   -webkit-animation-fill-mode: forwards;

//   &:first-child{
//     // animation-delay: 0.7s;

//   }

//   &:last-child{
//     // animation-delay: 0.5s;
//   }
// }

@keyframes titleAnimation {
    0% {
      transform: translateY(-50px);
      opacity: 0;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  }
  10% {
      transform: translateY(0);
      opacity: 1;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  90% {
       transform: translateY(0);
       opacity: 1;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  100% {
       transform: translateY(50px);
       opacity: 0;
      -webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
      clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);

  }
}
