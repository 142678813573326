.rooms-page {
  display: flex;
  .rooms-sidebar {
    transition: 200ms;
    left: 0;
    width: $roomsSidebarWidth;
    @extend .back-primary;
    @extend .border-gray-left;
    .rooms-sidebar-header {
      @extend .row-between;
      height: $roomHeaderH;
      padding: 1rem;
    }
    .rooms-sidebar-title {
      font-size: 1.5rem;
      font-weight: 600;
      @extend .darkgray;
    }
    .room-sidebar-body {
      overflow: auto;
      height: calc(100vh - #{$roomHeaderH} - #{$navbarHeight});
    }
    .room-item {
      @extend .row-between;
      border: 1px solid transparent;
      border-left: 3px solid transparent;
      cursor: pointer;
      .last-message {
        // width: 230px;
      }
      .short-from-now {
        font-size: 12px;
        white-space: nowrap;
        text-align: end;
      }
      .room-item-menu {
        padding: 0.5rem;
        font-size: 1.5rem;
        &:hover {
          color: $blue;
        }
      }
      &:hover {
        background-color: $LighterGray;
        @extend .darkgray;
      }
    }
    a.active {
      .room-item {
        border-left: 3px solid $blue;
        border-radius: $radius;
        margin-left: -1px;
        color: $blue;
        background-color: rgba($blue, 0.1) !important;
      }
    }
  }
  .secondary-sidebar {
    width: 0;
    transition: 500ms;
    border-left: 1px solid transparent;
    background-color: white;
    z-index: 10000;
    &.open {
      width: 350px;
      border-left: $lightBorder;
    }
    .secondary-sidebar-body {
      display: flex;
      height: 100%;
      flex-direction: column;
      .tabs {
        min-height: 60px;
      }
    }
  }
  .room-container {
    transition: 200ms;
    @extend .back-primary;
    height: $roomBodyH;
    flex: 3;
    display: flex;
    flex-direction: column;

    .file-preview {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 1rem;
      background: white;
      @extend .border-gray-top;
      bottom: 0;
      .img-preview {
        height: 250px;
        animation: filepreview-up 500ms ease;
      }
      .file-info {
        .filename {
          color: $DarkGray;
          font-size: $fontSizeLarge;
        }
        .filesize {
          color: $Gray;
          font-size: $fontSizeSmall;
        }
      }
    }
    .reply-preview {
      @extend .file-preview;
    }
    .room-header {
      padding: 0 1.0rem;
      height: $roomHeaderH;
      width: 100%;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      @extend .back-primary;
      @extend .border-gray-bottom;
      .room-live-actions {
        display: flex;
        color: $DarkGray;
      }
    }
    .room-body-container {
      flex-grow: 1;
      overflow: auto;
      .room-body {
        position: relative;
      }
    }
    .room-bottom-bar {
      display: flex;
      flex-direction: column;
      position: relative;
      input {
        font-size: $fontSizeLarge;
      }
      .send-btn {
        font-size: 180%;
        margin-left: 1rem;
        color: $blue;
        cursor: pointer;
      }
      .bottom-bar-actions {
        position: absolute;
        left: 0.5rem;
        top: 50%;
        transform: translate(0, -50%);
        @extend .row-flex;
      }
      .emojis-picker-btn {
        transition: 200ms;
        cursor: pointer;
        font-size: 180%;
        color: $blue;
        &:hover {
          color: darken($blue, 10)
        }
        &:active {
          color: lighten($blue, 10)
        }
      }
      .add-media-btn {
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
        svg {
          cursor: pointer;
          color: $blue;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .rooms-page {
    .rooms-sidebar {
      width: 3.5rem;
      .rooms-sidebar-header {
        justify-content: center;
      }
    }
  }
}

.messages-box {
  .chat-date-label {
    padding: 1rem 0 0.5rem 0;
    text-align: center;
    font-size: $fontSizeSmall;
    color: $DarkGray;
  }
  .self {
    flex-direction: row-reverse;
  }
  .other {
    flex-direction: row;
  }
  .picture {
    display: flex;
    padding: 1rem;
    img {
      height: 250px;
      min-height: 250px;
      border-radius: $radius;
    }
  }
  .reply-user {
    display: flex;
    padding: 0.25rem 1.5rem;
    color: $Gray;
    font-size: $fontSizeSmall;

  }
  .message {
    border-radius: 30px;
    padding: 0.5rem 1rem;
    word-wrap: break-word;
    max-width: 70%;
    &.only-emojis {
      font-size: 28px;
      background-color: transparent !important;
      padding: 0.5rem 0 !important;
    }
  }
  .self-message {
    display: flex;
    flex-direction: row-reverse;
    padding: 0 1rem 0.25rem 1rem;
    &:hover .message-menu {
      display: block;
      margin-right: 1rem;
    }
    .message {
      color: white;
      background-color: $blue;
    }
    .parent-message {
      background-color: #4057af; // opacity 0.7 fake
    }
  }
  .other-message {
    display: flex;
    flex-direction: row;
    padding: 0 1rem 0.25rem 0.5rem;
    .message {
      color: $MediumDark;
      background-color: #edeff3;
    }
    &:hover .message-menu {
      display: flex;
      margin-left: 1rem;
    }
    .parent-message {
      background-color: hsl(280, 6%, 41%); // opacity 0.9 fake
    }
  }
  .parent-message {
    padding: 0.5rem 1rem;
    border-radius: 30px;
    margin: 5px 0;
  }
  .message-menu {
    transition: 200ms;
    display: none;
    div {
      color: $Gray;
    }
  }
  .typing {
    // margin-left: 1rem;
    padding: 0 0.5rem 0.25rem 1rem;
    // background-color: $LighterGray;
  }
}
