
.tabs {
  display: flex;
  justify-content: space-around;
  padding: 0.25rem 0;
  align-items: flex-end;
  position: relative;
  min-height: 2.5rem;
  font-weight: 600;
  .tab-link {
    font-size: $fontSizeLarge;
    @extend .darkgray;
    text-align: center;
    width: 50%;
    cursor: pointer;
    &.active {
      color: $blue;
    }
    &:hover {
      color: $blue;
    }
  }
  .tab-indicator {
    height: 2px;
    position: absolute;
    width: 50%;
    bottom: 0;
    left: 0;
    display: flex;
    left: 50%;
    transition: 300ms;
    .i-content {
      height: 100%;
      width: 50%;
      margin: auto;
      background-color: $blue;
    }
  }
}
.tab-content {
  padding: 1rem 0;
  max-height: -webkit-fill-available;
  overflow: auto;
}

.light-tabs {
  .tabs {
    @extend .row-between;
    .left-tabs, .right-tabs {
      @extend .row-flex;
      width: 38%;
    }
  }
  .post-card.card.elevation {
    box-shadow: none;
  }
}