.group-avatars {
  @extend .row-flex;
  margin: 1rem;
  overflow: hidden;
  .avatar {
    margin-right: 1rem;
  }
}

.group-title {
  font-size: 1.3rem;
  font-weight: 800;
  padding: 1rem;
}

.group-createdat {
  color: $Gray;
  font-size: $fontSizeSmall;
}

.group-item {
  @extend .border-gray;
  border-top: 5px solid $blue;
  border-radius: 3px;
  .delete-btn {
    position: absolute;
    display: none;
    top: 0;
    right: 6px;
    font-size: 1rem;
  }
  &:hover {
    .delete-btn {
      display: block;
    }
  }
}
