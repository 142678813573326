.comments-list {
  .comment {
    padding-top: 1rem;
    font-size: $fontSizeSmall;
    color: $LightDark;
    .comment-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }
    .comment-body {
      background-color: $LighterGray;
      border-radius: 0 $radius $radius $radius;
      margin-left: 0.5rem;
      position: relative;
      padding: 0.5rem 0.75rem;
      &:before {
        content: "";
        position: absolute;
        z-index: 0;
        top: 0;
        left: -10px;
        border-radius: 10px;
        height: 12px;
        width: 15px;
        background: inherit;
        border-bottom-right-radius: 15px;
      }
      &:after {
        content: "";
          position: absolute;
          z-index: 1;
          top: 0;
          left: -12px;
          width: 12px;
          height: 12px;
          background: white;
          border-top-right-radius: 27px;
      }
    }
    .comment-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    // .comment-footer {
      // display: flex;
    // }
  }
}
.comment-input {
  display: flex;
  position: relative;
  .send-btn {
    animation: opacity 500ms;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    font-size: 22px;
    cursor: pointer;
    color: $blue;
    &:hover {
      color: darken($color: $blue, $amount: 10);
    }
  }
}