
@keyframes halfOpacity {
  from {opacity: 0;}
  to {opacity: 0.5;}
}
@keyframes opacity {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes mobileMenu {
  from { transform: translateX(-100%); }
  to   { transform: translateX(0); }
}

@keyframes animate-letter {
  from { transform: translateY(-100%); }
  to   { transform: translateY(0); }
}
@keyframes dropdown {
  from {opacity: 0; transform: translateY(20px);}
  to {opacity: 1; transform: translateY(0px);}
}

.animate-letter {
  color: red;
  animation: animate-letter 500ms ease;
}

@keyframes growup {
  0% {height: 100px}
  100% {height: calc(100vh - 60px - 1rem); overflow: auto;}
}

@keyframes pushnotif {
  from {transform: translate(-50%, -120%);}
  to {transform: translate(-50%, 0);}
}


@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}


@keyframes breathin {
  0% {color: $blue;}
  50% {color: $pink}
  100% {color: $blue;}
}