p {
  white-space: pre-line;
}

.darkgray {
  color: $DarkGray;
}
.gray {
  color: $Gray;
}

.mediumgray {
  color: $MediumGray;
}

.lightgray {
  color: $LightGray;
}

.text-small {
  font-size: $fontSizeSmall;
}

.text-pink {
  color: $pink;
}
.text-danger {
  color: $red;
}
.text-primary {
  color: $blue;
}
.text-success {
  color: $green;
}

.section-title {
  @extend .darkgray;
  font-size: $fontSizeLarge;
  font-weight: bold;
}

.success-message {
  background-color: rgba($green, 0.1);
  padding: 1rem 1.5rem;
  border-radius: 10px;
}

ul {
  margin-bottom: 0 !important;
}

.border-gray-left {
  border-left:  1px solid $MidGray;
}
.border-gray-right {
  border-right:  1px solid $MidGray;
}
.border-gray-top {
  border-top:  1px solid $MidGray;
}
.border-gray-bottom {
  border-bottom:  1px solid $MidGray;
}
.border-gray {
  border:  1px solid $MidGray;
}
.back-primary {
  background: white;
}
.back-secondary {
  background: $LighterGray;
}
.back-light {
  background: $LightGray;
}
.darkmode {
  .back-primary {
    background: $PrimaryDark;
    color: $LightGray;
  }
  .back-secondary {
    background: $SecondaryDark;
    color: $LightGray;
  }
  .back-light {
    background: $LightDark;
  }
  .border-gray-left {
    border-left: 1px solid $DarkGray;
  }
  .border-gray-right {
    border-right: 1px solid $DarkGray;
  }
  .border-gray-top {
    border-top: 1px solid $DarkGray;
  }
  .border-gray-bottom {
    border-bottom: 1px solid $DarkGray;
  }
  .border-gray {
    border: 1px solid $DarkGray;
  }
}


