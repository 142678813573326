.new-form-element-button {
  @extend .row-flex;
}

.form-element {
  cursor: pointer;
  @extend .row-between;
  border-radius: $radius;
  &:hover {
    background-color: $LightGray;
  }
}

.form-elements-editor {
  overflow: auto;
  max-height: calc(100vh - 450px);
  margin-right: -1rem;
  .grip-icon {
    color: transparent;
  }
  .delete-icon {
    opacity: 0;
  }
  .form-element:hover {
    .grip-icon {
      @extend .darkgray;
    }
    .delete-icon {
      opacity: 1;
    }
  }
}

.toolbar-item {
  padding: 0.5rem 1rem;
  @extend .darkgray;
  cursor: grab;
  border: 1px solid $MidGray;
  margin-bottom: 0.5rem;
  border-radius: $radius;
  &:hover {
    background-color: $LightGray;
  }
}

.SortableItem {
  cursor: grab;
  border-radius: $radius;
  border: 2px solid transparent;
  padding: 0.5rem;
  .gray-btn {
    opacity: 0;
  }
  &:hover {
    border: 2px solid rgba($blue, 0.5);
    .gray-btn {
      opacity: 1;
    }
  }
  &.selected, &.selected:hover {
    border: 2px solid $blue;
  }
}

.form-place-holder {
  border: 2px dashed $MidGray;
  border-radius: $radius;
  @extend .darkgray;
  padding: 1rem;
}

.preview-container {
  overflow: auto;
  height: calc(100vh - 260px);
}


.form-header {
  margin-bottom: 1.5rem;
}

.form-body {

}

.form-info {
  width: 100%;
  padding: 1rem;
  background-color: $cyan;
  border-radius: $radius;
  color: white;
  font-weight: 600;
}

.form-footer {
  text-align: center;
}

.style-btn {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 0 0.25rem;
  font-size: 1.3rem;
  cursor: pointer;
  border-radius: $radius;
  @extend .darkgray;
  @extend .border-gray;
  &.active {
    background-color: $blue;
    color: white;
    &:hover {
      background-color: $blue;
    }
  }
  &:hover {
    background-color: $LightGray;
  }
}

.public-form-container {
  height: 100vh;
}

.full-screen-preview {
  animation: opacity 500ms ease forwards;
  position: fixed;
  z-index: 10000;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100vw;
  background-color: white;
  .full-screen-preview-toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    height: 60px;
    background-color: #2f323c;
  }
}

.tag {
  padding: 0.5rem 1.25rem;
  background-color: $LighterGray;
  border-radius: 30px;
  margin-right: 1rem;
  cursor: pointer;
  &.selected {
    background-color: $blue;
    color: white;
  }
}

.form-builder-sidebar {
  flex: 0 0 300px;
  // margin-left: -1rem;
  // margin-bottom: -1rem;
  // padding: 1rem;
}
// .canDrop-holder {
//   transition: 200ms;
//   background-color: rgba($green, 0.1);
// }
// .canDrop-sortable {
//   border-top: 3px solid rgba($green, 0.1);
//   border-bottom: 3px solid rgba($green, 0.1);
// }