$layoutHeader: 60px;

#pop {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 1000;
}

.logo {
  @extend .row-center;
  width: 100%;
  padding: 0.25rem;
  .sidebar-title {
    font-size: 1.75rem;
    font-weight: 900;
    color: $pink;
  }
  img {
    // margin: auto;
    // margin-right: 0.25rem;
    height: auto;
  }
}

.app {
  display: flex;
}

.app-body {
  transition-duration: 400ms;
  padding: $layoutHeader 0px 0px 0px;
  flex: 1;
  min-height: 100vh;
  @extend .back-secondary;
  .page {
    margin: 1rem 1rem 0 1rem;
    box-shadow: $littleShadow;
    padding: 1rem;
    border-radius: 3px;
    @extend .back-primary;
  }
}


.sidebar {
  transition-duration: 400ms;
  overflow-x: hidden;
  flex: 0 0 220px;
  background-color: white;
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 100;
  @extend .border-gray-right;
  @extend .back-primary;
  .sidebar-header {
    height: $layoutHeader;
    display: flex;
  }
  .sidebar-profile {
    margin: 0.25rem 0;
    overflow: hidden;
    transition: 400ms;
    .profile-menu {
      @extend .row-flex;
      width: 100%;
      padding: 0.75rem;
      .account-dd {
        cursor: pointer;
        white-space: nowrap;
        &::after {
          display: inline-block;
          margin-left: 1em;
          vertical-align: 0.255em;
          content: "";
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
        }
      }
      .profile-menu-avatar {
        width: 45px;
        min-width: 45px;
      }
    }
  }
  .active .sidebar-item {
    // border-right: 5px solid $blue;
    color: $blue;
    background-color: rgba($blue, 0.1);
    position: relative;
    .sidebar-border {
      height: 100%;
      width: 4px;
      top: 0;
      position: absolute;
      background-color: $blue;
      right: 0;
      border-radius: 5px 0 0 5px;
    }
    &:hover {
      background-color: rgba($blue, 0.1);
    }
  }
}

.chat-sidebar-unread-value {
  position: absolute;
  right: 1rem;
  width: 26px;
  font-size: 0.8rem;
  opacity: 0.9;
  background-color: $blue;
  height: 26px;
  display: flex;
  align-items: center;
  font-weight: 600;
  border-radius: 30px;
  justify-content: center;
  color: white;
}

.sidebar-body {
  padding: 1rem 0;
}
.sidebar-item-label, .sidebar-title, .profile-name {
  animation: opacity 400ms ease;
  animation-fill-mode: forwards;
  opacity: 0;
}
.sidebar-item {
  // border-radius: 0 5px 5px 0;
  font-size: $fontSizeLarge;
  padding: 0.75rem 1rem;
  font-weight: 600;
  display: flex;
  cursor: pointer;
  .sidebar-item-icon {
    width: 40px;
    min-width: 40px;
  }
  svg {
    font-size: 1.2rem;
  }
  &:hover {
    background-color: $LightGray;
  }
}
.minimize-btn {
  transition: 400ms;
  position: absolute;
  bottom: 3rem;
  font-size: 1.4rem;
  right: 1rem;
  padding: 1rem 0.5rem;
  cursor: pointer;
}

.navbar {
  @extend .back-primary;
  box-shadow: $littleShadow;
  transition-duration: 400ms;
  height: $layoutHeader;
  position: fixed;
  width: 100%;
  padding: 0px 1rem 0px calc(220px + 1rem);
  display: flex;
  .left-navbar {
    flex: 1;
  }
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.modern-navbar {
  @extend .navbar;
  padding: 0 1.5rem;
  background-color: white;
  transition: 200ms;
  box-shadow: rgba(123, 137, 148, 0.23) 0px 0px 4px 0px;
  .active.modern-nav-item {
    border-bottom: 4px solid $blue;
    color: $blue;
    &:hover {
      background-color: rgba($blue, 0.1);
    }
  }
  .modern-nav-item {
    font-size: 1.5rem;
    padding: 0.5rem 2.75rem;
    margin: 0 0.25rem;
    border-radius: $radius;
    &:hover {
      background-color: $LightGray;
    }
  }
}

.mini {
  .navbar {
    padding: 0px 1.5rem 0px 85px;
  }
  .sidebar {
    flex: 0 0 60px;
  }
  .minimize-btn {
    transform: rotate(180deg);
  }
}

@include media-breakpoint-down(md) {
  .navbar {
    padding: 0 1.5rem !important;
    .left-navbar {
      flex: 2;
    }
  }
}

@include media-breakpoint-down(sm) {

  .navbar {
    padding: 0 1rem !important;
    .left-navbar {
      flex: 3;
    }
  }
  .app-body .page {
    margin: 0 !important;
  }
}

.modernmode {
  .page {
    margin: 0 !important;
  }
}


.page-header {
  height: calc(60px + 0.5rem);
}


.divider {
  height: 1px;
  margin: 0 1rem;
}


.mobile-menu {
  .overlay {
    animation: halfOpacity 400ms ease forwards;
    z-index: 10000;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: $layoutHeader;
    left: 0;
    bottom: 0;
    background-color: #182142;
  }
  .mobile-menu-panel {
    animation: mobileMenu 400ms ease-out;
    position: fixed;
    width: 250px;
    top: $layoutHeader;
    left: 0;
    z-index: 100000;
    height: 100vh;
    bottom: 0;
    background: white;
    // .icon-nav-item {
    //   width: 2.5rem;
    // }
    // .mobile-nav-menu {
    //   padding: 0.5rem 0;
    //   .nav-item {
    //     cursor: pointer;
    //     transition: 200ms;
    //     padding: 1rem;
    //     border-radius: $smallRadius;
    //     font-size: $largeFontSize;
    //     color: $DarkGray;
    //     svg {
    //       font-size: $largerFontSize;
    //       margin-right: 1.5rem;
    //     }
    //     &.active {
    //       // background-color: $navbarTextColor;
    //       color: $Blue;
    //     }
    //   }
    //   .mobile-profile-btn {
    //     @extend .row-flex;
    //     padding: 0.75rem;
    //     color: $DarkGray;
    //     .avatar {
    //       margin-right: 0.75rem;
    //     }
    //   }
    //   a.active .mobile-profile-btn {
    //     color: $Blue;
    //   }
    // }
  }
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $LightGray;
  border-radius: $radius;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dedfe2;
  border-radius: $radius;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dedfe2;
}
